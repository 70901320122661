<template>
  <div class="manualNot">
    <Nothing/>
  </div>
</template>

<script>
export default {
  name: "manualNot",
  data() {
    return {};
  },
  created() {
  },
  methods: {}
};
</script>

<style scoped lang="scss">
.manualNot {
  padding: 60px;
}
@media (min-width: 751px) and (max-width: 960px) {
}
@media (min-width: 961px) {
}
</style>
